import { LoadingOutlined } from "@ant-design/icons"
import { ArrowLeftOutlined } from "@ant-design/icons"
import CustomFilterbar from "@components/CustomFilterBarAnonimatta"
import Gallery from "@components/Gallery"
import LikedModels from "@components/likedModels"
import PostFeed from "@components/PostFeedAnonimatta"
import RecommendedProfiles from "@components/RecommendedProfilesAnonimatta"
import SearchModel from "@components/searchModel"
import { useStores } from "@hooks/use-stores"
import ListEssay from "@modules/Essay/Site/components/ListEssay"
import translate from "@src/i18n"
import { getParamsId, getParamsUsername } from "@utils/path"
import { Col, Row, Tabs } from "antd"
import cx from "classnames"
import { navigate } from "gatsby"
import _get from "lodash/get"
import React, { useEffect, useState } from "react"
import { useMedia } from "react-use-media"

import { getWindow } from "../../utils/getWindow"
import EmptyState from "./components/EmptyState"
import Header from "./components/Header"
import PostNotUser from "./components/PostNotUser"
import styles from "./style.module.less"

const { TabPane } = Tabs

const Profile = ({
  empty = false,
  isProfile = true,
  refreshFeed,
  setRefreshFeed,
}) => {
  const [gallery, setGallery] = useState(false)
  const [visibleModal, setVisibleModal] = useState(false)
  const [profileData, setProfileData] = useState(null)

  const [profileRecommendedData, setProfileRecommendedData] = useState(null)

  const [postData, setPostData] = useState([])
  const [dataContact, setDataContact] = useState(null)
  const [totalPosts, setTotalPosts] = useState()
  const [essayData, setEssayData] = useState(null)
  const [totalEssays, setTotalEssays] = useState()
  const [isClient, setIsClient] = useState(false)
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 8,
  })
  const [paginationEssay, setPaginationEssay] = useState({
    current: 1,
    pageSize: 4,
  })
  const [isBottom, setIsBottom] = useState(false)
  const [loading, setLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState("1")
  const [followers, setFollowers] = useState(0)
  const [imFollowing, setImFollowing] = useState(false)
  const [deleteButton, setDeleteButton] = useState(false)

  const [isSubscriber, setIsSubscriber] = useState(false)

  const [filter, setFilter] = useState("all")

  const { profile, authSite, feed } = useStores()
  const { user } = authSite

  const {
    getAffiliatedByUsername,
    getSubscriberByUsername,
    findAffiliatedByProfileView,
    findSubscriberByProfileView,
    getAllPostProfile,
    getContactById,
    getEssayProfile,
    postNewFollow,
    deletedFollowers,
  } = profile

  const { getMostFollowed } = feed

  const isMobile = useMedia({
    maxWidth: 767,
  })

  const isTablet = useMedia({
    maxWidth: 1024,
  })

  const isDesktop = useMedia({
    minWidth: 1025,
  })

  const isDesktopLarge = useMedia({
    minWidth: 1366,
  })

  const data = {
    urlProfile: profileData?.urlProfile ?? profileData?.urlPhoto,

    urlCoverPage: _get(profileData, "urlCoverPage", ""),
    name: _get(profileData, "name", ""),
    username: _get(profileData, "username", ""),
    nickname: _get(profileData, "nickname", ""),
    type: _get(profileData, "type", ""),
    location: `${_get(profileData, "city", "")}, ${_get(
      profileData,
      "state",
      ""
    )}`,
    description: _get(profileData, "describe", ""),
    likes: _get(profileData, "likes", 0),
    visibleWhatsapp: _get(profileData, "visibleWhatsapp", ""),
    instagram:
      profileData && profileData.instagram ? profileData.instagram : "",
    twitter: profileData && profileData.twitter ? profileData.twitter : "",
    publicEmail:
      dataContact && dataContact.publicEmail ? dataContact.publicEmail : "",
    phoneNumber:
      dataContact && dataContact.phoneNumber ? dataContact.phoneNumber : "",
  }

  useEffect(() => {
    if (!user) {
      getWindow().localStorage.clear()
      handleGetProfileView()
    } else {
      handleGetProfileByUsername()
    }
  }, [])

  useEffect(() => {
    setIsClient(true)
    if (user) handleMostFollowed()
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  useEffect(() => {
    if (isBottom && postData && postData.length && currentTab === "1") {
      setPagination((prevState) => ({
        current: prevState.current + prevState.pageSize,
        pageSize: prevState.pageSize,
      }))
    }

    if (isBottom && essayData && essayData.length && currentTab === "2") {
      setPaginationEssay((prevState) => ({
        ...prevState,
        pageSize: prevState.pageSize + 1,
      }))
    }
  }, [isBottom])

  useEffect(() => {
    if (profileData) {
      if (postData && postData.length < totalPosts && currentTab === "1") {
        handleGetAllPostProfile()
      }
      if (essayData && essayData.length < totalEssays && currentTab === "2") {
        handleGetEssayProfile()
      }
    }
  }, [pagination, paginationEssay])

  useEffect(() => {
    if (profileData) {
      handleGetAllPostProfile()
      handleGetEssayProfile()
    }
  }, [profileData])

  useEffect(() => {
    if (refreshFeed && profileData) {
      setPostData(null)
      handleGetAllPostProfile()
      setRefreshFeed(false)
    }
  }, [refreshFeed])

  const handleGetProfileByUsername = async () => {
    const username = getParamsUsername()
    const affiliated = await getAffiliatedByUsername(username)
    const subscriber = await getSubscriberByUsername(username)

    if (affiliated && !affiliated.error) {
      setProfileData(affiliated)
      setFollowers(affiliated.followers)
      setImFollowing(affiliated.imFollowing)
      if (affiliated.username === user.username) setDeleteButton(true)
    } else if (subscriber && !subscriber.error) {
      setIsSubscriber(true)
      if (subscriber.username === user.username) setDeleteButton(true)
      setProfileData(subscriber)
    } else {
      navigate("/pagina-nao-encontrada")
    }
    handleGetDataById(affiliated?.id || subscriber?.id)
  }

  const handleGetProfileView = async () => {
    const username = getParamsUsername()
    const affiliated = await findAffiliatedByProfileView(username)
    const subscriber = await findSubscriberByProfileView(username)

    if (affiliated && !affiliated.error) {
      setProfileData(affiliated)
      setFollowers(affiliated.followers)
      setImFollowing(affiliated.imFollowing)
    } else if (subscriber && !subscriber.error) {
      navigate("/login")
    } else {
      navigate("/pagina-nao-encontrada")
    }
  }

  const handleGetDataById = async (id) => {
    const response = await getContactById(id)
    setDataContact(response)
  }

  const handleGetAllPostProfile = async () => {
    setLoading(true)

    const response = await getAllPostProfile(profileData.id, pagination)

    const posts = _get(response, "data", [])
    const totalPosts = _get(response, "total", 0)

    setPostData([...postData, ...posts])
    setTotalPosts(totalPosts)
    setLoading(false)
    setIsBottom(false)
  }

  const handleGetEssayProfile = async () => {
    setLoading(true)

    const response = await getEssayProfile(profileData.id, paginationEssay)

    const essays = _get(response, "data", [])
    const totalEssays = _get(response, "total", 0)

    setEssayData(essays)
    setTotalEssays(totalEssays)
    setLoading(false)
    setIsBottom(false)
  }

  const handleFollowers = async () => {
    const affiliatedId = profileData?.id
    const { data } = await postNewFollow(affiliatedId)

    if (data.success === true) {
      setFollowers(data.followers)
      setImFollowing(true)
    }
  }

  const handleUnfollowers = async () => {
    const affiliatedId = profileData?.id

    const { data } = await deletedFollowers(affiliatedId)

    if (data.success === true) {
      setFollowers(data.followers)
      setImFollowing(false)
    }
  }

  const handleScroll = () => {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop

    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight

    const innerHeight = window.innerHeight + 50

    if (scrollTop + innerHeight >= scrollHeight) {
      setIsBottom(true)
    }
  }

  const handleSearch = () => {
    if (!visibleModal) {
      setVisibleModal(true)
      return
    }

    setVisibleModal(false)
  }

  const handleClick = () => {
    setGallery((prev) => !prev)
  }

  const handleBack = () => {
    navigate("/feed")
  }

  const TabName = (props) => {
    const { name, quantity } = props

    return (
      <div className="flex flex-row items-baseline">
        <span className="mr4 fw6">{name}</span>
        <span className={styles.numberQuantity}>{quantity}</span>
      </div>
    )
  }

  const handleMostFollowed = async () => {
    const idProfileLoged = _get(user, "id", "")
    const response = await getMostFollowed(idProfileLoged)

    const newResponse = response.mostFollowed.map((item) => ({
      id: item.id,
      urlProfile: item.urlProfile,
      name: item.name,
      location: `${item.city}, ${item.state}`,
      followers: item.followersCnt,
      affiliatedNickname: item.affiliatedNickname,
    }))
    setProfileRecommendedData(newResponse)
  }

  const renderHeaderDesktop = () => {
    return user ? (
      <Header
        isMobile={isMobile}
        isDesktop={isDesktop}
        isDesktopLarge={isDesktopLarge}
        data={data}
        handleFollowers={handleFollowers}
        followers={followers}
        handleUnfollowers={handleUnfollowers}
        imFollowing={imFollowing}
        deleteButton={deleteButton}
        isSubscriber={isSubscriber}
      />
    ) : (
      <Header
        isMobile={isMobile}
        isDesktop={isDesktop}
        isDesktopLarge={isDesktopLarge}
        data={data}
        handleFollowers={handleFollowers}
        followers={followers}
        handleUnfollowers={handleUnfollowers}
        imFollowing={imFollowing}
        deleteButton={deleteButton}
      />
    )
  }

  const renderPosts = () => {
    return user ? (
      <PostFeed
        setRefreshFeed={setRefreshFeed}
        data={postData}
        nickname={data.nickname}
        recommendedProfiles={false}
        isProfile={isProfile}
        isPost={true}
      />
    ) : (
      <PostNotUser />
    )
  }
  const renderEssays = () => {
    return (
      <Row
        className={cx("w-100")}
        style={
          isDesktopLarge
            ? { width: "100%", justifyContent: "center" }
            : { width: "100%" }
        }
      >
        {gallery ? (
          <Gallery handleClick={handleClick} />
        ) : (
          <Col span={24}>
            <ListEssay
              data={essayData}
              mostLiked={false}
              handleClick={handleClick}
              isProfile={isProfile}
            />
          </Col>
        )}
      </Row>
    )
  }

  if (!postData) {
    return (
      <div className="loading-global">
        <p className="flex flex-column justify-center items-center">
          <img src={require(`@images/loading.gif`)} />
          {data && data.length ? null : ""}
        </p>
      </div>
    )
  }

  const post = true
  const essay = true

  if (!isClient) return null

  return isMobile ? (
    <>
      <Row className="mb72">
        {user ? (
          <div
            className={cx(
              "flex items-center w-100 absolute",
              styles.backHeader
            )}
          >
            <ArrowLeftOutlined
              height={18}
              width={21}
              className="mh16 mobileIcon"
              onClick={handleBack}
            />
          </div>
        ) : (
          ""
        )}

        <Header
          isMobile={isMobile}
          isDesktop={isDesktop}
          data={data}
          handleFollowers={handleFollowers}
          followers={followers}
          handleUnfollowers={handleUnfollowers}
          imFollowing={imFollowing}
          deleteButton={deleteButton}
          isSubscriber={isSubscriber}
        />

        {post || essay ? (
          <Tabs
            defaultActiveKey={post ? "1" : "2"}
            onChange={(key) => {
              setIsBottom(false)
              setCurrentTab(key)
            }}
          >
            <TabPane tab={<TabName name="Posts" />} key="1">
              {!empty ? (
                <>
                  {user ? (
                    <PostFeed
                      setRefreshFeed={setRefreshFeed}
                      data={postData}
                      recommendedProfiles={false}
                      isProfile={isProfile}
                      nickname={data.nickname}
                      isPost={true}
                    />
                  ) : (
                    <div
                      className="public-profile-image"
                      style={{
                        background: `url(${require("@images/public-feed-img.jpg")})`,
                      }}
                    >
                      <div className="exclusive-content-profile">
                        <div className="lock-content-img">
                          <img src={require("@images/locker.svg")} />
                        </div>

                        <a href="/planos">Acesso exclusivo para assinantes.</a>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <Row justify="center" className="mv48">
                  <Col span={16} className="tc">
                    <span className="tc f14 f18-ns b scorpion-main">
                      {translate("profile.emptyStatePosts")}
                    </span>
                  </Col>
                  <Col span={24} className="mt72">
                    <LikedModels handleSearch={handleSearch} />
                  </Col>
                </Row>
              )}
            </TabPane>
            <TabPane tab={<TabName name="Ensaios" />} key="2">
              {!empty ? (
                <>
                  {user ? (
                    <div
                      className={cx(
                        "essay-list-profile",
                        styles.profile__essays
                      )}
                    >
                      <ListEssay
                        data={essayData}
                        mostLiked={false}
                        handleClick={handleClick}
                        isProfile={isProfile}
                      />
                    </div>
                  ) : (
                    <div
                      className="public-profile-image"
                      style={{
                        background: `url(${require("@images/public-feed-img.jpg")})`,
                      }}
                    >
                      <div className="exclusive-content-profile">
                        <div className="lock-content-img">
                          <img src={require("@images/locker.svg")} />
                        </div>

                        <a href="/planos">Acesso exclusivo para assinantes.</a>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <Row justify="center" className="mv48">
                  <Col span={20} className="tc">
                    <span className="tc f14 f18-ns b scorpion-main">
                      {translate("profile.emptyStateEssays")}
                    </span>
                  </Col>
                </Row>
              )}
            </TabPane>
          </Tabs>
        ) : (
          <Row justify="center" className="mv32">
            <Col span={20} className="tc">
              <span className="tc f14 f18-ns b scorpion-main">
                {translate("profile.emptyState")}
              </span>
            </Col>
            <Col span={24} className="mv32">
              <LikedModels handleSearch={handleSearch} />
            </Col>
          </Row>
        )}
        {user && (
          <SearchModel visible={visibleModal} handleSearch={handleSearch} />
        )}
      </Row>
    </>
  ) : (
    <>
      <div className={isTablet ? "mb72" : "flex flex-column"}>
        {isTablet && (
          <div
            className={cx(
              "flex items-center w-100 absolute",
              styles.backHeader
            )}
          >
            <ArrowLeftOutlined
              height={18}
              width={21}
              className="mh16 mobileIcon"
              onClick={handleBack}
            />
            <span className="f16 fw6 mine-shaft mv16 mr-auto">
              {translate("profile")}
            </span>
          </div>
        )}
        {renderHeaderDesktop()}

        {isSubscriber ? (
          ""
        ) : (
          <>
            {post || essay ? (
              <div className="page__center" style={{ width: "100%" }}>
                {isDesktopLarge ? (
                  <div
                    className={cx("flex flex-row", styles.profile__container)}
                  >
                    <div
                      className={cx(
                        "flex flex-column posts__public",
                        styles.profile__posts
                      )}
                    >
                      <Tabs
                        defaultActiveKey={post ? "1" : "2"}
                        onChange={(key) => {
                          setIsBottom(false)
                          setCurrentTab(key)
                        }}
                        className="tabs-anonimatta tabs__feed__profile"
                      >
                        <TabPane
                          key="1"
                          tab={
                            <TabName
                              className="tab__feed__posts"
                              name="Posts"
                            />
                          }
                        >
                          {!empty ? (
                            renderPosts()
                          ) : (
                            <EmptyState
                              type="emptyStatePosts"
                              handleSearch={handleSearch}
                            />
                          )}
                        </TabPane>

                        <TabPane
                          key="2"
                          tab={
                            <TabName
                              name={translate("essays")}
                              className="tab__feed__essays"
                            />
                          }
                        >
                          {!empty ? (
                            <>
                              {user ? renderEssays() : <PostNotUser />}
                              {loading ? (
                                <div className={styles.loadingMore}>
                                  <img src={require(`@images/loading.gif`)} />
                                </div>
                              ) : null}
                            </>
                          ) : (
                            <EmptyState
                              type="emptyStateEssays"
                              handleSearch={handleSearch}
                            />
                          )}
                        </TabPane>
                      </Tabs>

                      {loading ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "50px",
                          }}
                        >
                          <img
                            style={{ maxWidth: "40px" }}
                            src={require(`@images/loading.gif`)}
                          />
                        </div>
                      ) : null}
                    </div>
                    {user ? (
                      <div className={styles.profile__recommended}>
                        {profileRecommendedData && (
                          <RecommendedProfiles
                            profileData={profileRecommendedData}
                            handleMostFollowed={handleMostFollowed}
                            isProfile={true}
                          />
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <Row
                    className={styles.profile__posts}
                    justify="space-between feed__content"
                  >
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={16}
                      className="feed__area"
                    >
                      <Tabs
                        defaultActiveKey={post ? "1" : "2"}
                        onChange={(key) => {
                          setIsBottom(false)
                          setCurrentTab(key)
                        }}
                        className="tabs-anonimatta tabs__feed__profile"
                      >
                        <TabPane
                          tab={
                            <TabName
                              className="tab__feed__posts"
                              name="Posts"
                            />
                          }
                          key="1"
                        >
                          {!empty ? (
                            renderPosts()
                          ) : (
                            <EmptyState
                              type="emptyStatePosts"
                              handleSearch={handleSearch}
                            />
                          )}
                        </TabPane>

                        <TabPane
                          tab={
                            <TabName
                              name={translate("essays")}
                              className="tab__feed__essays"
                            />
                          }
                          key="2"
                        >
                          {!empty ? (
                            <>
                              {user ? renderEssays() : <PostNotUser />}
                              {loading ? (
                                <div className={styles.loadingMore}>
                                  <img src={require(`@images/loading.gif`)} />
                                </div>
                              ) : null}
                            </>
                          ) : (
                            <EmptyState
                              type="emptyStateEssays"
                              handleSearch={handleSearch}
                            />
                          )}
                        </TabPane>
                      </Tabs>

                      {loading ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "50px",
                          }}
                        >
                          <img
                            style={{ maxWidth: "40px" }}
                            src={require(`@images/loading.gif`)}
                          />
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                )}
              </div>
            ) : (
              <EmptyState type="emptyState" handleSearch={handleSearch} />
            )}
          </>
        )}

        {user && (
          <SearchModel visible={visibleModal} handleSearch={handleSearch} />
        )}
      </div>
    </>
  )
}

export default Profile
