import LikedModels from "@components/likedModels"
import translate from "@src/i18n"
import { Col, Row } from "antd"
import React from "react"

const EmptyState = ({ type, handleSearch }) => {
  return (
    <Row justify="center" className="mt24">
      <Col span={20} className="tc">
        <span className="tc f14 f20-ns b scorpion-main">
          {type === "emptyStatePosts" && translate("profile.emptyStatePosts")}
          {type === "emptyStateEssays" && translate("profile.emptyStateEssays")}
          {type === "emptyState" && translate("profile.emptyState")}
        </span>
      </Col>
      <Col span={24} className="mv72">
        <LikedModels handleSearch={handleSearch} />
      </Col>
    </Row>
  )
}
export default EmptyState
