import TranslateWrapper from "@components/TranslateWrapper"
import { useStores } from "@hooks/use-stores"
import Profile from "@modules/ProfileAnonimatta"
import { Redirect } from "@reach/router"
import React, { useEffect, useState } from "react"

const ProfilePage = ({ refreshFeed, setRefreshFeed }) => {
  const [isClient, setIsClient] = useState(false)
  const { authSite } = useStores()
  const { user } = authSite

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (!isClient) return null
  if (user && user.status !== 1) {
    return <Redirect to="/minha-conta#inactive=true" noThrow />
  }

  return (
    <TranslateWrapper>
      {user ? (
        <div className="page profile__page">
          <div className="logged__profile">
            <Profile
              refreshFeed={refreshFeed}
              setRefreshFeed={setRefreshFeed}
            />
          </div>
        </div>
      ) : (
        <div className="page profile__page">
          <div className="public__page flex-column flex">
            <Profile />
          </div>
        </div>
      )}
    </TranslateWrapper>
  )
}

export default ProfilePage
