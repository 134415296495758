import React from "react"

const PostNotUser = () => {
  return (
    <div
      className="public-profile-image"
      style={{
        background: `url(${require("@images/public-feed-img.jpg")})`,
      }}
    >
      <div className="exclusive-content-profile">
        <div className="lock-content-img">
          <img src={require("@images/locker.svg")} />
        </div>

        <a href="/planos">Acesso exclusivo para assinantes.</a>
      </div>
    </div>
  )
}
export default PostNotUser
