import { useStores } from "@hooks/use-stores"
import translate from "@src/i18n"
import { getWatermarkURL } from "@utils/path"
import { Button } from "antd"
import cx from "classnames"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useIntl } from "react-intl"

import styles from "../style.module.less"

const Header = ({
  isMobile,
  isDesktop,
  data,
  followers,
  handleFollowers,
  handleUnfollowers,
  imFollowing,
  deleteButton,
  isSubscriber,
}) => {
  const {
    urlProfile,
    urlCoverPage,
    name,
    username,
    nickname,
    type,
    location,
    likes,
    description,
  } = data
  const { authSite } = useStores()
  const { user } = authSite
  const [isClient, setIsClient] = useState(false)
  const intl = useIntl()

  const srcProfile = `${process.env.S3_IMAGES}/${urlProfile}`
  const srcCoverPage = `${process.env.S3_IMAGES}/${urlCoverPage}`

  const followerText = intl.formatMessage({ id: "followers" })
  const likesText = intl.formatMessage({ id: "likes" })

  useEffect(() => {
    setIsClient(true)
  }, [])

  function abbreviateNumber(value) {
    var newValue = value
    if (value >= 1000) {
      var suffixes = ["", "k", "m", "b", "t"]
      var suffixNum = Math.floor(("" + value).length / 3)
      var shortValue = ""
      for (var precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat(
          (suffixNum != 0
            ? value / Math.pow(1000, suffixNum)
            : value
          ).toPrecision(precision)
        )
        var dotLessShortValue = (shortValue + "").replace(/[^a-zA-Z 0-9]+/g, "")
        if (dotLessShortValue.length <= 2) {
          break
        }
      }
      if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1)
      newValue = shortValue + suffixes[suffixNum]
    }
    return newValue
  }

  if (!isClient) return null
  // console.log(!isDesktop, isDesktop)

  return !isDesktop ? (
    <>
      {isSubscriber ? (
        <div className="header-subs"></div>
      ) : (
        <div
          style={{
            backgroundImage: `url('${srcCoverPage}')`,
            height: "250px",
            width: "100%",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></div>
      )}

      {user ? (
        <Helmet>
          <title>
            {nickname} (@{username}) - Anonimatta
          </title>
        </Helmet>
      ) : (
        <Helmet>
          <title>Anonimatta</title>
        </Helmet>
      )}

      <div
        className={cx("page__center page__center_pt0", styles.mobile__profile)}
        style={{ width: "100%" }}
      >
        <div className={`${styles.author} ${styles.author__big}`}>
          <div className={styles.author__container}>
            <div className={styles.author__wrapper}>
              <div
                className={styles.author__avatar}
                style={{
                  backgroundImage: `url('${srcProfile}')`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              ></div>

              <div className={styles.author__wrapper__details}>
                <div className={styles.author__username}>
                  {nickname || name}
                </div>
                <div className={styles.author__details__container}>
                  <div className={styles.author__profile}>
                    @{username}{" "}
                    <span
                      className={styles.author__profile__point}
                      style={{ padding: "0 5px", color: "#808191" }}
                    >
                      •
                    </span>{" "}
                  </div>
                  {type && (
                    <>
                      <div className={styles.author__profile__type}>
                        {translate(`profile.${type}`)}{" "}
                        <span style={{ padding: "0 5px", color: "#808191" }}>
                          •
                        </span>{" "}
                        {location}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className={styles.author__parameters}>
              <div className={styles.author__parameter}>
                <span>{abbreviateNumber(followers)} seguidores</span>
              </div>
              <div className={styles.author__parameter}>
                <span>{abbreviateNumber(likes)} curtidas</span>
              </div>
            </div>
          </div>

          <div className={styles.author__description}>
            <div className={styles.author__description__text}>
              {description}
            </div>
            {user ? (
              <div className={styles.author__btns}>
                {!deleteButton ? (
                  !imFollowing ? (
                    <Button
                      block
                      className={styles.author__btn}
                      onClick={handleFollowers}
                    >
                      {translate("follow")}
                    </Button>
                  ) : (
                    <Button
                      className={(styles.author__btn, styles.btn__active)}
                      onClick={handleUnfollowers}
                    >
                      {translate("following")}
                    </Button>
                  )
                ) : (
                  <a
                    className={styles.author__edit__btn}
                    href="/minha-conta"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Editar
                  </a>
                )}
              </div>
            ) : (
              <div className={styles.author__btns}>
                <a
                  className={styles.author__btn}
                  href="/planos"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Seguir
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      {/* HEADER APARECE SE FOR CRIADOR E NÃO APARECE SE FOR ASSINANTE */}
      {isSubscriber ? (
        <div className="header-subs"></div>
      ) : (
        <div
          style={{
            backgroundImage: `url('${srcCoverPage}')`,
            height: "400px",
            width: "100%",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></div>
      )}

      {/* MOSTRA O PERFIL COMPLETO COM FEED E REOCMENDADOS SE O USUÁRIO ESTIVER LOGADO  */}
      {user ? (
        <div
          className="page__center page__center_pt0"
          style={{ width: "100%" }}
        >
          <Helmet>
            <title>
              {nickname} (@{username}) - Anonimatta
            </title>
          </Helmet>
          <div className={`${styles.author} ${styles.author__big}`}>
            <div className={styles.author__container}>
              <div className={styles.author__details}>
                <div className={styles.author__info}>
                  <div
                    className={styles.ava}
                    style={{
                      backgroundImage: `url('${srcProfile}')`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  ></div>
                  <div className={styles.author__wrap}>
                    <div className={`${styles.author__man} ${styles.h2}`}>
                      {nickname || name}
                    </div>
                    <div className="username__profile">
                      @{username}{" "}
                      {type && (
                        <>
                          <span style={{ padding: "0 8px", color: "#808191" }}>
                            •
                          </span>{" "}
                          {translate(`profile.${type}`)}{" "}
                          <span style={{ padding: "0 8px", color: "#808191" }}>
                            •
                          </span>{" "}
                          {location}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className={styles.author__btns}>
                  {!deleteButton ? (
                    !imFollowing ? (
                      <Button
                        block
                        className={styles.author__btn}
                        onClick={handleFollowers}
                      >
                        {translate("follow")}
                      </Button>
                    ) : (
                      <Button
                        className={(styles.author__btn, styles.btn__active)}
                        onClick={handleUnfollowers}
                      >
                        {translate("following")}
                      </Button>
                    )
                  ) : (
                    <a
                      className={styles.author__edit__btn}
                      href="/minha-conta"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Editar
                    </a>
                  )}
                </div>
              </div>

              <div className={styles.author__description}>
                <div className={styles.author__parameters}>
                  <div className={styles.author__parameter}>
                    {abbreviateNumber(followers)} seguidores
                  </div>
                  <div className={styles.author__parameter}>
                    {abbreviateNumber(likes)} curtidas
                  </div>
                </div>

                <div className={styles.author__description__text}>
                  {description}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // A PARTIR DAQUI É O PERFIL PÚBLICO DO USUÁRIO (SEM FEED E SEM RECOMENDADOS)
        <div
          className="page__center page__center_pt0"
          style={{ width: "100%" }}
        >
          <Helmet>
            <title>
              {nickname} (@{username}) - Anonimatta
            </title>
          </Helmet>

          <div className={`${styles.author} ${styles.author__big}`}>
            <div className={styles.author__container}>
              <div className={styles.author__details}>
                <div className={styles.author__info}>
                  <div
                    className={styles.ava}
                    style={{
                      backgroundImage: `url('${srcProfile}')`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  ></div>
                  <div className={styles.author__wrap}>
                    <div className={`${styles.author__man} ${styles.h2}`}>
                      {nickname || name}
                    </div>
                    <div className={styles.username__profile}>
                      @{username}{" "}
                      {type && (
                        <>
                          <span style={{ padding: "0 8px", color: "#808191" }}>
                            •
                          </span>{" "}
                          {translate(`profile.${type}`)}{" "}
                          <span style={{ padding: "0 8px", color: "#808191" }}>
                            •
                          </span>{" "}
                          {location}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <a
                  className={styles.author__btn}
                  href="/planos"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Seguir
                </a>
              </div>

              <div className={styles.author__description}>
                <div className={styles.author__parameters}>
                  <div className={styles.author__parameter}>
                    {abbreviateNumber(followers)} seguidores
                  </div>
                  <div className={styles.author__parameter}>
                    {abbreviateNumber(likes)} curtidas
                  </div>
                </div>

                <div style={{ color: "#808191", marginBottom: "30px" }}>
                  {description}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Header
